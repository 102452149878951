import { CommandButton, Icon } from 'office-ui-fabric-react';
import React from 'react';
import styles from './PieChartLegend.module.scss';
import { IAnswerOption } from 'src/store/business/interfaces/IAnswerOption';
import round from 'lodash/round';
import { store } from 'src/store';
import { setIsAttendeesPanelOpen, setVotable } from 'src/store/business/actions';

export const PieChartLegend = (props: any) => {
	const { payload } = props;

	const getOptionResultInPercent = (option: IAnswerOption): number => {
		const totalNumberOfVotes = props.payload.reduce(
			(sum, currentOption) => sum + currentOption.payload.answerOption.votesCount,
			0
		);
		let optionResultInPercent = (option.votesCount * 100) / totalNumberOfVotes;
		optionResultInPercent = isNaN(optionResultInPercent) ? 0 : optionResultInPercent;
		optionResultInPercent = round(optionResultInPercent, 1);
		return optionResultInPercent;
	};

	return (
		<div className={styles.legendContainer}>
			{payload.map((entry, index) => (
				<div key={`legend-${index}`} className={styles.legendItemContainer}>
					<div className={styles.answerTextContainer}>
						<Icon iconName={'StatusCircleInner'} style={{ color: entry.color }}></Icon>
						<div className={styles.answerText} title={entry.value}>
							{entry.value}
						</div>
					</div>
					<div className={styles.votesCountButtonContainer}>
						<div className={styles.votesCount}>
							<div>{getOptionResultInPercent(entry.payload.answerOption)}%</div>
							<div>&nbsp;&nbsp;({entry.payload.answerOption.votesCount} Stimmen)</div>
						</div>

						<CommandButton
							className={styles.detailsButton}
							title='Stimmen anzeigen'
							iconProps={{ iconName: 'ReceiptCheck' }}
							onClick={() => {
								store.dispatch(setVotable(entry.payload.answerOption));
								store.dispatch(setIsAttendeesPanelOpen(true));
							}}
						/>
					</div>
				</div>
			))}
		</div>
	);
};
